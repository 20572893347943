import React, { Component } from "react";
import {
  Link
} from "react-router-dom";
import './Draw.css';

class Draw extends Component {
  render() {
    return (
      <div>
        <div class="apps-header">
          <h2>Drawings</h2>
          <p>Choose wisely</p>
        </div>
        <div class="drawing-grid-container">
          <div class="drawing-grid-box">
            <p>Sketches</p>
            <Link to="/draw/sketches">
               <button type="button">
                    Clicky!
               </button>
           </Link>
          </div>
          <div class="drawing-grid-box">Comics</div>
          <div class="drawing-grid-box">Doodles</div>
        </div>
      </div>
    );
  }
}

export default Draw;
