import React, { Component } from "react";

import './Home.css';

class Home extends Component {
  render() {
    return (
      <div class="home-container">
        <h2>HELLO</h2>
        <p>Welcome to the world!</p>

        <p>Drawing, Writing, Coding, etc.</p>

        <p> Here's a dog</p>
      </div>
    );
  }
}

export default Home;
