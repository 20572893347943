import React, { Component } from "react";
import './Comics.css';

class Comics extends Component {
  render() {
    return (
      <div>
        <div class="comics-header">
          <h2>Comics</h2>
          <p>Choose wisely</p>
        </div>
        <div class="comics-grid-container">
          <div class="comics-grid-box">Adventures of Coffee and Powder</div>
          <div class="comics-grid-box">Aliens!</div>
          <div class="comics-grid-box">Podkins</div>
          <div class="comics-grid-box">4</div>
          <div class="comics-grid-box">5</div>
          <div class="comics-grid-box">6</div>
          <div class="comics-grid-box">7</div>
          <div class="comics-grid-box">8</div>
        </div>
      </div>
    );
  }
}

export default Comics;
