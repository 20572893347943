import React, { Component } from "react";

class SymptomTracker extends Component {
  render() {
    return (
      <div>
        <div class="sketches-header">
          <h2>Symptom Tracker</h2>
          <p>Mauris sem velit, vehicula eget sodales vitae,
          rhoncus eget sapien:</p>
        </div>
        <ol>
          <li>Nulla pulvinar diam</li>
          <li>Facilisis bibendum</li>
          <li>Vestibulum vulputate</li>
          <li>Eget erat</li>
          <li>Id porttitor</li>
        </ol>
      </div>
    );
  }
}

export default SymptomTracker;
