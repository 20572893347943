import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import {
  Link
} from "react-router-dom";
import './Sketches.css';

class Sketches extends Component {
  render() {
    return (
      <div>
        <div class="sketches-header">
          <h2>Sketches</h2>
          <p>Choose your hero</p>
        </div>
        <br/>
        <div class="hero-grid-container">
          <div class="hero-container">
            <div class="hero-title">Ryan</div>
            <div class="hero-picture ryan"></div>
            <div class="hero-button">
              <Link to="/draw/sketches/ryan">
                <Button variant="success" block>
                      Choose this hero!
                </Button>
              </Link>
            </div>
          </div>
          <div class="hero-container">
            <div class="hero-title">Sawyer</div>
            <div class="hero-picture sawyer"></div>
            <div class="hero-button">
              <Link to="/draw/sketches/sawyer">
                <Button variant="success" block>
                      Choose this hero!
                </Button>
              </Link>
            </div>
          </div>
          <div class="hero-container">
            <div class="hero-title">Josh</div>
            <div class="hero-picture josh"></div>
            <div class="hero-button">
              <Link to="/draw/sketches/josh">
                <Button variant="success" block>
                      Choose this hero!
                </Button>
              </Link>
            </div>
          </div>
          <div class="hero-container">
            <div class="hero-title">Sam</div>
            <div class="hero-picture sam"></div>
            <div class="hero-button">
              <Link to="/draw/sketches/sam">
                <Button variant="success" block>
                      Choose this hero!
                </Button>
              </Link>
            </div>
          </div>
          <div class="maddie-container">
            <div class="hero-title">Maddie</div>
            <div class="hero-picture maddie"></div>
            <div class="hero-button">
              <Link to="/draw/sketches/maddie">
                <Button variant="success" block>
                      Choose this hero!
                </Button>
              </Link>
            </div>
          </div>
          <div class="hero-container">
            <div class="hero-title">Luke</div>
            <div class="hero-picture luke"></div>
            <div class="hero-button">
              <Link to="/draw/sketches/luke">
                <Button variant="success" block>
                      Choose this hero!
                </Button>
              </Link>
            </div>
          </div>
          <div class="hero-container">
            <div class="hero-title">Jennie</div>
            <div class="hero-picture jennie"></div>
            <div class="hero-button">
              <Link to="/draw/sketches/jennie">
                <Button variant="success" block>
                      Choose this hero!
                </Button>
              </Link>
            </div>
          </div>
          <div class="hero-container">
            <div class="hero-title">Family</div>
            <div class="hero-picture family"></div>
            <div class="hero-button">
              <Link to="/draw/sketches/family">
                <Button variant="success" block>
                      Choose this hero!
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sketches;
