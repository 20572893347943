import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "../../css/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Button from 'react-bootstrap/Button';
import {
  Link
} from "react-router-dom";

class SketchesSam extends Component {
  render() {
    return (
      <div>
        <div class="sketches-header">
          <h2>Sketches by Sam</h2>
          <p>Choose wisely!</p>
        </div>
        <br/>
        <div class="sketch-grid-container">
          <div class="sketch-grid-box" style={{ backgroundImage: "url(../images/sam/Kyle_by_sam.jpg)" }}>
            <h2>Kyle</h2>
            <div class="sketch-description">
              <p> Kyle from Guess Who!</p>
              <div class="hero-picture kyle"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SketchesSam;
