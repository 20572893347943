import React, { Component } from "react";
import {
  Link
} from "react-router-dom";
import './Write.css';

class Write extends Component {
  render() {
    return (
      <div>
        <div class="write-header">
          <h2>Write</h2>
          <p>Choose wisely</p>
        </div>
        <div class="write-grid-container">
          <div class="write-grid-box">
            <p>Story 1</p>
            <Link to="/write/story1">
              <button type="button">
                    Clicky!
              </button>
          </Link>
          </div>
          <div class="write-grid-box">Story 2</div>
          <div class="write-grid-box">Story 3</div>
          <div class="write-grid-box">4</div>
          <div class="write-grid-box">5</div>
          <div class="write-grid-box">6</div>
          <div class="write-grid-box">7</div>
          <div class="write-grid-box">8</div>
        </div>
      </div>
    );
  }
}

export default Write;
