import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "../../css/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class SketchesRyan extends Component {
  render() {
    return (
      <div>
        <div class="sketches-header">
          <h2>Sketches of Ryan</h2>
          <p>Choose wisely!</p>
        </div>
      </div>
    )
  }
};

export default SketchesRyan;
