import React, { Component } from "react";
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';

import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import Draw from "./Draw";
import Sketches from "./draw/Sketches";
import SketchesRyan from "./draw/sketches/SketchesRyan"
import SketchesSawyer from "./draw/sketches/SketchesSawyer"
import SketchesJosh from "./draw/sketches/SketchesJosh"
import SketchesSam from "./draw/sketches/SketchesSam"
import SketchesLuke from "./draw/sketches/SketchesLuke"
import SketchesMaddie from "./draw/sketches/SketchesMaddie"
import SketchesJennie from "./draw/sketches/SketchesJennie"
import SketchesFamily from "./draw/sketches/SketchesFamily"
import Comics from "./draw/Comics";
import Write from "./Write";
import MenuButton from "./MenuButton";
import Menu from "./Menu";
import SymptomTracker from "./apps/SymptomTracker";
import Apps from "./Apps";
import Books from "./books/Books";

class Main extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      visible: false
    };

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  handleMouseDown(e) {
    this.toggleMenu();

    console.log("clicked");
    e.stopPropagation();
  }

  toggleMenu() {
    this.setState({
        visible: !this.state.visible
    });
  }

  render() {
    return (
      <HashRouter>
        <div>
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/">RBP</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <NavDropdown title="Draw" id="draw-nav-dropdown">
                  <NavDropdown.Item href="#draw">Draw Home</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#draw/sketches">Sketches</NavDropdown.Item>
                  <NavDropdown.Item href="#draw/comics">Comics</NavDropdown.Item>
                  <NavDropdown.Item href="#draw/doodles">Doodles</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="#write">Write</Nav.Link>
                <NavDropdown title="Apps" id="apps-nav-dropdown">
                  <NavDropdown.Item href="#apps">Apps Home</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#apps/symptom-tracker">Symptom Tracker</NavDropdown.Item>
                  <NavDropdown.Item href="#apps/crayon-army">Crayon Army</NavDropdown.Item>
                  <NavDropdown.Item href="#apps/coffee-and-the-cave">Coffee and the Cave</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Books" id="apps-nav-dropdown">
                  <NavDropdown.Item href="#books">Books Home</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#books/sawyer">Sawyers Faves</NavDropdown.Item>
                  <NavDropdown.Item href="#books/ryan">Ryans Faves</NavDropdown.Item>
                  <NavDropdown.Item href="#books/maddie">Maddies Faves</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Form inline>
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-success">Search</Button>
              </Form>
            </Navbar.Collapse>
          </Navbar>
          <div className="content">
            <Route exact path="/" component={Home}/>
            <Route exact path="/draw" component={Draw}/>
            <Route exact path="/draw/sketches" component={Sketches}/>
            <Route path="/draw/sketches/ryan" component={SketchesRyan}/>
            <Route path="/draw/sketches/sawyer" component={SketchesSawyer}/>
            <Route path="/draw/sketches/josh" component={SketchesJosh}/>
            <Route path="/draw/sketches/sam" component={SketchesSam}/>
            <Route path="/draw/sketches/luke" component={SketchesLuke}/>
            <Route path="/draw/sketches/maddie" component={SketchesMaddie}/>
            <Route path="/draw/sketches/jennie" component={SketchesJennie}/>
            <Route path="/draw/sketches/family" component={SketchesFamily}/>
            <Route exact path="/draw/comics" component={Comics}/>
            <Route exact path="/write" component={Write}/>
            <Route exact path="/apps" component={Apps}/>
            <Route path="/apps/symptom-tracker" component={SymptomTracker}/>
            <Route exact path="/books" component={Books}/>
          </div>
        </div>
      </HashRouter>
    );
  }
}

export default Main;
