import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import {
  Link
} from "react-router-dom";
import './Books.css';

class Books extends Component {
  render() {
    return (
      <div>
        <div class="books-header">
          <h2>Books</h2>
          <p>Choose your book recommendations</p>
        </div>
        <br/>
        <div class="books-grid-container">
          <div class="books-container">
            <div class="book-title">Sawyer</div>
            <div class="book-grid-container">
              <div class="book-picture hello-universe"></div>
              <div class="book-picture the-goldfish-boy"></div>
            </div>
            <div class="book-button">
              <Link to="/books/sawyer">
                <Button variant="success" block>
                      See Sawyers recommendations!
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Books;
