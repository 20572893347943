import React, { Component } from "react";
import {
  Link
} from "react-router-dom";
import './Apps.css';

class Apps extends Component {
  render() {
    return (
      <div>
        <div class="apps-header">
          <h2>Apps</h2>
          <p>Choose wisely</p>
        </div>
        <div class="apps-grid-container">
          <div class="apps-grid-box">
            <h2>Symptom Tracker</h2>
            <div class="app-description">
              <p> App to track all your symtpoms.</p>
              <Link to="/apps/symptom-tracker">
                <button type="button">
                      Click to see more!
                </button>
              </Link>
            </div>
          </div>
          <div class="apps-grid-box">Crayon Army</div>
          <div class="apps-grid-box">Coffee and the Cave</div>
          <div class="apps-grid-box">
            <h2>App #4</h2>
            <div class="app-description">
              <p>This is the greatest of apps.</p>
              <p>This is app number 4.</p>
            </div>
          </div>
          <div class="apps-grid-box">5</div>
          <div class="apps-grid-box">6</div>
          <div class="apps-grid-box">7</div>
          <div class="apps-grid-box">8</div>
        </div>
      </div>
    );
  }
}

export default Apps;
