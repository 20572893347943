import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "../../css/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class SketchesMaddie extends Component {
    render() {
        return (
          <div>
            <div>
            THIS IS A TEST
            </div>
            <Carousel>
                <div>
                  <img src="images/CoffeeSleepingXmas.jpg" />
                  <p className="legend">Legend 1</p>
                </div>
            </Carousel>
          </div>
        );
    }
};

export default SketchesMaddie;
