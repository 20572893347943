import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "../../css/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Button from 'react-bootstrap/Button';
import {
  Link
} from "react-router-dom";

class SketchesJosh extends Component {
  render() {
    return (
      <div>
        <div class="sketches-header">
          <h2>Sketches by Josh</h2>
          <p>Choose wisely!</p>
        </div>
        <br/>
        <div class="sketch-grid-container">
          <div class="sketch-grid-box" style={{ backgroundImage: "url(../images/josh/ripped_pikachu.jpg)" }}>
            <h2>Ripped Pikachu</h2>
            <div class="sketch-description">
              <p> Pikachu has been working out! </p>
              <div class="hero-picture pikachu"></div>
            </div>
          </div>
          <div class="sketch-grid-box" style={{ backgroundImage: "url(../images/josh/stranger_things_monsters.jpg)" }}>
            <h2>Stranger Things Monsters</h2>
            <div class="sketch-description">
              <p> These monsters be strange! </p>
              <div class="hero-picture stranger-things"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default SketchesJosh;
